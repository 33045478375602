import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loading from './components/Loading'
import ScrollIntoView from './utils/ScrollIntoView';
import { withTracker } from './utils/GoogleAnalytics'
const Home = lazy(() => import('./containers/Home'))
const NotFound = lazy(() => import('./containers/error/NotFound'))
const WhatWeDo = lazy(() => import('./pages/WhatWeDo'))
const Work = lazy(() => import('./pages/Work'))
const ProjectView = lazy(() => import('./pages/ProjectView'))
const AboutUs = lazy(() => import('./pages/AboutUs'))
const Careers = lazy(() => import('./pages/Careers'))
const CareersView = lazy(() => import('./pages/CareersView'))
const ServiceView = lazy(() => import('./pages/ServiceView'))
const Conatact = lazy(() => import('./pages/Contact'))

class App extends Component {
  _renderRouter() {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={["/", "/index.html"]} component={withTracker(Home)} />
          <Route exact path="/whatwedo" component={withTracker(WhatWeDo)} />
          <Route exact path="/works" component={withTracker(Work)} />
          <Route exact path="/work/:name_project*" component={withTracker(ProjectView)} />
          <Route exact path="/about-us" component={withTracker(AboutUs)} />
          <Route exact path="/careers" component={withTracker(Careers)} />
          <Route exact path="/career/:title*" component={withTracker(CareersView)} />
          <Route exact path="/service" component={withTracker(ServiceView)} />
          <Route exact path="/contact" component={withTracker(Conatact)} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    )
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollIntoView>
          {this._renderRouter()}
        </ScrollIntoView>
      </BrowserRouter>
    )
  }
}

export default App;
