import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css'
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import App from './App';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  // debounceDelay: 250, 
  // throttleDelay: 300,
  duration: 800,
  offset: 200,
  delay: 100,
  once: true
});

const them = createMuiTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
  ,
  palette: {
    primary: {
      light: '#5eeae6',
      main: '#05b7b4',
      dark: '#008684',
      contrastText: '#000000',
    },
    type: 'dark'
  },
})

ReactDOM.render(
  <MuiThemeProvider theme={them}>
    <App />
  </MuiThemeProvider>
  , document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
