import React from 'react'
import './loading.css'

export default function index() {
  return (
    <div className="flex items-center justify-center w-screen h-screen " style={{ backgroundColor: "#05b7b4" }}>
      <div className="lds-ripple"><div></div><div></div></div>
    </div>
  )
}
